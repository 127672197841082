import React from 'react'
import Link from 'gatsby-link'
import Layout from "../components/layout"

const HowItWorks = () => (
  <Layout>
    <h1>How it HowItWorks</h1>
    <p>Test</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default HowItWorks
